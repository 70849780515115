export const ATTRIBUTE_MODELS = [
  {
    id: 0,
    option: 'Hội thoại',
  },
  {
    id: 1,
    option: 'Khách hàng',
  },
];

export const ATTRIBUTE_TYPES = [
  {
    id: 0,
    option: 'Chữ',
  },
  {
    id: 1,
    option: 'Số',
  },
  {
    id: 4,
    option: 'Link',
  },
  {
    id: 5,
    option: 'Ngày',
  },
  {
    id: 6,
    option: 'Danh sách',
  },
  {
    id: 7,
    option: 'Checkbox',
  },
];
export const REVERSE_ATTRIBUTE_TYPES = {
  text: 'Chữ',
  number: 'Số',
  link: 'Link',
  date: 'Ngày',
  list: 'Danh sách',
  checkbox: 'Checkbox',
};
