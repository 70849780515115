<template>
  <settings-section
    :title="$t('INBOX_MGMT.ZALO_REAUTHORIZE.TITLE')"
    :sub-title="$t('INBOX_MGMT.ZALO_REAUTHORIZE.SUBTITLE')"
  >
    <!-- <a class="fb--login" href="#" @click="tryFBlogin">
      <img
        src="~dashboard/assets/images/channels/facebook_login.png"
        alt="Facebook-logo"
      />
    </a> -->
    <a href="#" @click="tryZalologin">
      <img
        src="~dashboard/assets/images/channels/zalo_login.png"
        alt="Zalo-logo"
      />
    </a>
  </settings-section>
</template>

<script>
// /* global FB */
import SettingsSection from '../../../../../components/SettingsSection';
import alertMixin from 'shared/mixins/alertMixin';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import { encode as base64encode } from 'base64-arraybuffer';
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import axios from 'axios';

export default {
  components: {
    SettingsSection,
  },
  mixins: [alertMixin, globalConfigMixin],
  props: {
    inboxId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
    }),
  },
  mounted() {
    // this.initFB();
    // this.loadFBsdk();
  },

  created() {
    const zaloCodeVerifier = Cookies.get('ZaloCodeVerifier');
    const queryString =
      window.location.search === ''
        ? window.location.hash.substring(1)
        : window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('code');
    if (code && zaloCodeVerifier) {
      this.getZaloAccessToken(
        {
          authorizationCode: code,
          codeVerifier: zaloCodeVerifier,
        },
        {
          onSuccess: res => {
            // createZalos({
            //   data: {
            //     code: res?.code ?? '',
            //     longLivedToken: res?.refreshToken ?? '',
            //     name: res?.name ?? '',
            //     token: res?.accessToken ?? '',
            //   },
            // });
            // console.log(res);
            // const tmp = [];
            // tmp.push({
            //   name: res?.name ?? '',
            //   id: res?.code ?? '',
            //   access_token: res?.accessToken ?? '',
            //   long_live_token: res?.refreshToken ?? '',
            // });
            // this.pageList = tmp;
            // this.user_access_token = res?.accessToken;
            // console.log(
            //   this.pageList,
            //   this.user_access_token,
            //   !this.user_access_token
            // );
            // this.setZaloData(res);
            this.$store
              .dispatch('inboxes/reauthorizeZalo', {
                accessToken: res?.accessToken ?? '',
                refreshToken: res?.refreshToken ?? '',
                pageId: res?.code ?? '',
              })
              .then(data => {
                // router.replace({
                //   name: 'settings_inboxes_add_agents',
                //   params: { page: 'new', inbox_id: data.id },
                // });
                console.log(data);
              })
              .catch(() => {
                // this.isCreating = false;
              });
          },
          onError: () => {
            // showNotification({
            //   color: 'red',
            //   message: t('message.error.create'),
            // });
          },
        }
      );
    }
  },

  methods: {
    // initFB() {
    //   if (window.fbSDKLoaded === undefined) {
    //     window.fbAsyncInit = () => {
    //       FB.init({
    //         appId: window.chatwootConfig.fbAppId,
    //         xfbml: true,
    //         version: window.chatwootConfig.fbApiVersion,
    //         status: true,
    //       });
    //       window.fbSDKLoaded = true;
    //       FB.AppEvents.logPageView();
    //     };
    //   }
    // },

    // loadFBsdk() {
    //   ((d, s, id) => {
    //     let js;
    //     // eslint-disable-next-line
    //     const fjs = (js = d.getElementsByTagName(s)[0]);
    //     if (d.getElementById(id)) {
    //       return;
    //     }
    //     js = d.createElement(s);
    //     js.id = id;
    //     js.src = 'https://connect.facebook.net/en_US/sdk.js';
    //     fjs.parentNode.insertBefore(js, fjs);
    //   })(document, 'script', 'facebook-jssdk');
    // },

    // tryFBlogin() {
    //   FB.login(
    //     response => {
    //       if (response.status === 'connected') {
    //         this.reauthorizeFBPage(response.authResponse.accessToken);
    //       } else if (response.status === 'not_authorized') {
    //         // The person is logged into Facebook, but not your app.
    //         this.showAlert(this.$t('INBOX_MGMT.DETAILS.ERROR_FB_AUTH'));
    //       } else {
    //         // The person is not logged into Facebook, so we're not sure if
    //         // they are logged into this app or not.
    //         this.showAlert(this.$t('INBOX_MGMT.DETAILS.ERROR_FB_AUTH'));
    //       }
    //     },
    //     {
    //       scope:
    //         'pages_manage_metadata,pages_messaging,instagram_basic,pages_show_list,pages_read_engagement,instagram_manage_messages',
    //       auth_type: 'reauthorize',
    //     }
    //   );
    // },
    // async reauthorizeFBPage(omniauthToken) {
    //   try {
    //     await this.$store.dispatch('inboxes/reauthorizeFacebookPage', {
    //       omniauthToken,
    //       inboxId: this.inboxId,
    //     });
    //     this.showAlert(
    //       this.$t('INBOX_MGMT.FACEBOOK_REAUTHORIZE.MESSAGE_SUCCESS')
    //     );
    //   } catch (error) {
    //     this.showAlert(
    //       this.$t('INBOX_MGMT.FACEBOOK_REAUTHORIZE.MESSAGE_ERROR')
    //     );
    //   }
    // },

    // zalo
    tryZalologin() {
      console.log(1);
      this.handleZaloLogin();
    },
    getZaloAccessToken(payload, cb) {
      this.getAccessToken(payload)
        .then(atRes => {
          // console.log(atRes);
          // console.log(JSON.parse(atRes.data.body));
          const atData = JSON.parse(atRes.data.body || '{}');
          this.getOA({
            accessToken: atData.access_token,
          }).then(oaRes => {
            // console.log(oaRes);
            const oaData = JSON.parse(oaRes?.data?.body || '{}');
            if (oaData?.error !== 0 || oaData?.message !== 'Success') {
              cb?.onError?.(oaData?.message);
              return;
            }
            cb?.onSuccess?.({
              code: oaData?.data?.oa_id,
              name: oaData?.data?.name,
              accessToken: atData.access_token,
              refreshToken: atData.refresh_token,
            });
          });
        })
        .catch(cb?.onError)
        .finally(cb?.onFinally);
    },
    getAccessToken(payload) {
      const urlData = '/api/v1/integrations/zalo/auth';
      const fetchPromise = new Promise((resolve, reject) => {
        axios
          .post(
            urlData,
            {
              app_id: this.globalConfig.zaloAppId,
              code: payload.authorizationCode,
              grant_type: 'authorization_code',
              code_verifier: payload.codeVerifier,
            },
            {
              headers: {
                secret_key: this.globalConfig.zaloAppSecret,
              },
            }
          )
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
      return fetchPromise;
    },
    getOA(payload) {
      const urlData = '/api/v1/integrations/zalo/oa';
      const fetchPromise = new Promise((resolve, reject) => {
        axios
          .post(
            urlData,
            {
              access_token: payload.accessToken,
            },
            {
              headers: {
                access_token: payload.accessToken,
              },
            }
          )
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
      return fetchPromise;
    },
    async handleZaloLogin() {
      try {
        const {
          codeChallenge,
          codeVerifier,
          url,
        } = await this.getZaloAuthorizationCodeURL();

        const expires = this.getDateAfterDuration({
          duration: { input: 10, unit: 'm' },
        });
        console.log(codeChallenge, codeVerifier, expires);

        Cookies.set('ZaloCodeChallenge', codeChallenge, {
          expires,
        });
        Cookies.set('ZaloCodeVerifier', codeVerifier, {
          expires,
        });

        window.open(url, '_self');
      } catch (error) {
        console.log(error);
      } finally {
        console.log('here');
        // this.hasLoginStarted = false;
        // setLoading({ zalo: false });
      }
    },

    async getZaloAuthorizationCodeURL() {
      const { codeChallenge, codeVerifier } = await this.generatePKCEPair();

      const url = `https://oauth.zaloapp.com/v4/oa/permission?${this.toQueryString(
        {
          app_id: this.globalConfig.zaloAppId,
          redirect_uri: document.URL,
          code_challenge: codeChallenge,
          // state: generateRandomString({ length: 6 }),
          state: this.generateString(6),
        }
      )}`;

      return { codeChallenge, codeVerifier, url };
    },

    getDateAfterDuration({
      currentDate = new Date(),
      duration: { input, unit },
    }) {
      return dayjs(currentDate)
        .add(input, unit)
        .toDate();
    },

    toQueryString(rawQuery) {
      const query = rawQuery || {};
      const keys = Object.keys(query).filter(
        key => typeof query[key] !== 'undefined'
      );
      return keys
        .map(key =>
          Array.isArray(query[key])
            ? this.addArrayQueryParam(query, key)
            : this.addQueryParam(query, key)
        )
        .join('&');
    },

    addQueryParam(query, key) {
      return this.encodeQueryParam(key, query[key]);
    },
    addArrayQueryParam(query, key) {
      const value = query[key];
      return value.map(v => this.encodeQueryParam(key, v)).join('&');
    },
    encodeQueryParam(key, value) {
      const encodedKey = encodeURIComponent(key);
      return `${encodedKey}=${encodeURIComponent(
        typeof value === 'number' ? value : `${value}`
      )}`;
    },

    async generatePKCEPair() {
      // const codeVerifier = generateRandomString({
      //   length: verifierLength,
      //   type: 'alphanumeric',
      // });
      const codeVerifier = this.generateString(43);

      const encoder = new TextEncoder();
      const data = encoder.encode(codeVerifier);
      const digest = await window.crypto.subtle.digest('SHA-256', data);
      const base64Digest = base64encode(digest);

      const codeChallenge = base64Digest
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');

      return {
        codeVerifier,
        codeChallenge,
      };
    },

    generateString(length) {
      const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = ' ';
      const charactersLength = characters.length;
      for (let i = 0; i < length; i += 1) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }

      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.fb--login {
  img {
    max-width: 240px;
    padding: $space-normal 0;
  }
}
</style>
