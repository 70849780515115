/* global axios */
import ApiClient from '../ApiClient';

class FBCommentChannel extends ApiClient {
  constructor() {
    super('facebook_indicators', { accountScoped: true });
  }

  create(params) {
    return axios.post(
      `${this.url.replace(
        this.resource,
        ''
      )}callbacks/register_facebook_comment`,
      params
    );
  }

  reauthorizeFacebookComment({ omniauthToken, inboxId }) {
    return axios.post(`${this.baseUrl()}/callbacks/reauthorize_comment`, {
      omniauth_token: omniauthToken,
      inbox_id: inboxId,
    });
  }
}

export default new FBCommentChannel();
