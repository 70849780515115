<template>
  <!-- <mention-box :items="items" @mention-select="handleMentionClick">
    <template slot-scope="{ item }">
      <div class="canned-item-wrapper">
        <div>
          <strong>{{ item.label }}</strong>
        </div>
        <div>
          <strong>{{ item.title }}</strong>
          <p class="canned-item__description">{{ item.description }}</p>
        </div>
      </div>
    </template>
  </mention-box> -->
  <ul
    ref="mentionsListContainer"
    v-if="items.length"
    class="vertical dropdown menu mention--box"
    :class="{ 'with-bottom-border': items.length <= 4 }"
  >
    <li
      v-for="(item, index) in items"
      :id="`mention-item-${index}`"
      :key="item.id"
      :class="{ active: index === selectedIndex }"
      @click="onListItemSelection(index)"
      @mouseover="onHover(index)"
    >
      <div class="canned-wrapper">
        <div class="canned-label-wrapper">
          <strong class="canned-label">/{{ item.label }}</strong>
        </div>
        <div class="canned-details-wrapper">
          <strong class="canned-title">{{
            item.title || $t('CANNED_MGMT.LIST.NO_TITLE')
          }}</strong>
          <div class="canned-description">{{ item.description }}</div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex';
// import MentionBox from '../mentions/MentionBox.vue';
import mentionSelectionKeyboardMixin from '../mentions/mentionSelectionKeyboardMixin';

export default {
  // components: { MentionBox },
  mixins: [mentionSelectionKeyboardMixin],
  props: {
    searchKey: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedIndex: 0,
    };
  },
  computed: {
    ...mapGetters({
      cannedMessages: 'getCannedResponses',
    }),
    items() {
      return this.cannedMessages.map(cannedMessage => ({
        label: cannedMessage.short_code,
        key: cannedMessage.short_code,
        title: cannedMessage.title,
        description: cannedMessage.content,
        attachments: cannedMessage.files,
      }));
    },
  },
  watch: {
    searchKey() {
      this.fetchCannedResponses();
    },
    items(newItems) {
      if (newItems.length < this.selectedIndex + 1) {
        this.selectedIndex = 0;
      }
    },
    selectedIndex() {
      const container = this.$refs.mentionsListContainer;
      const item = container.querySelector(
        `#mention-item-${this.selectedIndex}`
      );
      if (item) {
        const itemTop = item.offsetTop;
        const itemBottom = itemTop + item.offsetHeight;
        const containerTop = container.scrollTop;
        const containerBottom = containerTop + container.offsetHeight;
        if (itemTop < containerTop) {
          container.scrollTop = itemTop;
        } else if (itemBottom + 50 > containerBottom) {
          container.scrollTop = itemBottom - container.offsetHeight + 50;
        }
      }
    },
  },
  mounted() {
    this.fetchCannedResponses();
  },
  methods: {
    fetchCannedResponses() {
      this.$store.dispatch('getCannedResponse', { searchKey: this.searchKey });
    },
    handleKeyboardEvent(e) {
      this.processKeyDownEvent(e);
    },
    onHover(index) {
      this.selectedIndex = index;
    },
    onListItemSelection(index) {
      this.selectedIndex = index;
      this.onSelect();
    },
    onSelect() {
      this.$emit('click', this.items[this.selectedIndex]);
    },
  },
};
</script>

<style scoped lang="scss">
.mention--box {
  background: var(--white);
  border-radius: var(--border-radius-normal);
  border-top: 1px solid var(--color-border);
  box-shadow: var(--shadow-medium);
  font-size: var(--font-size-small);
  left: 0;
  bottom: 100%;
  line-height: 1.2;
  max-height: 20rem;
  overflow: auto;
  padding: var(--space-small) var(--space-small) 0 var(--space-small);
  position: absolute;
  width: 100%;
  z-index: 100;

  &.with-bottom-border {
    border-bottom: var(--space-small) solid var(--white);

    li {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  li {
    height: 50px;
    max-height: 50px;
    align-items: center;
    border-radius: var(--border-radius-normal);
    display: flex;
    padding: var(--space-small);

    &.active {
      background: var(--s-50);

      .mention--user-name {
        color: var(--s-900);
      }
      .mention--email {
        color: var(--s-800);
      }
    }

    &:last-child {
      margin-bottom: var(--space-small);
    }
  }
}
.canned-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: var(--space-small);

  .canned-label-wrapper {
    width: var(--space-mega);
    flex-shrink: 0;
    word-break: break-word;

    .canned-label {
      color: var(--w-600);
    }
  }

  .canned-details-wrapper {
    flex-grow: 1;
    max-width: calc(100% - var(--space-mega) - var(--space-small));
    overflow: hidden;

    .canned-title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .canned-description {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-top: var(--space-micro);
    }
  }
}
</style>
