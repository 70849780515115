<template>
  <modal
    :show.sync="show"
    :on-close="onClose"
    :close-on-backdrop-click="false"
    :on-click-modal="hideEmojiPicker"
  >
    <div class="column content-box">
      <woot-modal-header
        :header-title="$t('CANNED_MGMT.ADD.TITLE')"
        :header-content="$t('CANNED_MGMT.ADD.DESC')"
      />
      <form class="row" @submit.prevent="addCannedResponse()">
        <div class="medium-12 columns">
          <label :class="{ error: $v.shortCode.$error }">
            {{ $t('CANNED_MGMT.ADD.FORM.SHORT_CODE.LABEL') }}
            <input
              v-model.trim="shortCode"
              type="text"
              :placeholder="$t('CANNED_MGMT.ADD.FORM.SHORT_CODE.PLACEHOLDER')"
              @input="$v.shortCode.$touch"
            />
          </label>
        </div>

        <div class="medium-12 columns">
          <label :class="{ error: $v.title.$error }">
            {{ $t('CANNED_MGMT.ADD.FORM.TITLE.LABEL') }}
            <input
              v-model.trim="title"
              type="text"
              :placeholder="$t('CANNED_MGMT.ADD.FORM.TITLE.PLACEHOLDER')"
              @input="$v.title.$touch"
            />
          </label>
        </div>

        <div class="medium-12 columns">
          <div class="content-label-wrapper">
            <label :class="{ error: $v.content.$error }">
              {{ $t('CANNED_MGMT.ADD.FORM.CONTENT.LABEL') }}
            </label>
            <div class="editor-tools">
              <woot-button
                type="button"
                :title="$t('CONVERSATION.REPLYBOX.TIP_EMOJI_ICON')"
                icon="emoji"
                emoji="😊"
                color-scheme="secondary"
                variant="smooth"
                size="small"
                @click="toggleEmojiPicker"
              />
              <emoji-input
                v-if="showEmojiPicker"
                v-on-clickaway="hideEmojiPicker"
                class="emoji-dialog--expanded"
                :on-click="emojiOnClick"
              />

              <file-upload
                ref="upload"
                :size="4096 * 4096"
                :accept="allowedFileTypes"
                :multiple="true"
                :drop="true"
                :drop-directory="false"
                :data="{
                  direct_upload_url: '/rails/active_storage/direct_uploads',
                  direct_upload: true,
                }"
                @input-file="onFileUpload"
              >
                <woot-button
                  type="button"
                  class-names="button--upload"
                  :title="$t('CONVERSATION.REPLYBOX.TIP_ATTACH_ICON')"
                  icon="attach"
                  emoji="📎"
                  color-scheme="secondary"
                  variant="smooth"
                  size="small"
                />
              </file-upload>
            </div>
            <div class="action-wrap">
              <div class="tabs-title">
                <span :class="charLengthClass">
                  {{ characterLengthWarning }}
                </span>
              </div>
            </div>
          </div>
          <div class="editor-wrap">
            <woot-message-editor
              v-model="content"
              class="message-editor"
              :class="{ editor_warning: $v.content.$error }"
              :enable-variables="true"
              :enable-canned-responses="false"
              :placeholder="$t('CANNED_MGMT.ADD.FORM.CONTENT.PLACEHOLDER')"
              :update-selection-with="updateEditorSelectionWith"
              @clear-selection="clearEditorSelection"
              @blur="$v.content.$touch"
              @focus="onFocus"
            />
            <div
              v-if="hasAttachments"
              class="attachment-preview-box"
              @paste="onPaste"
            >
              <attachment-preview
                :attachments="attachedFiles"
                :remove-attachment="removeAttachment"
              />
            </div>
          </div>
        </div>

        <div class="medium-12">
          <input v-model="isPrivate" type="checkbox" :value="false" />
          <label for="is_private">
            {{ $t('CANNED_MGMT.ADD.FORM.IS_PRIVATE.LABEL') }}
          </label>
        </div>

        <div class="modal-footer">
          <div class="medium-12 columns">
            <woot-submit-button
              :disabled="
                $v.content.$invalid ||
                  $v.shortCode.$invalid ||
                  $v.title.$invalid ||
                  addCanned.showLoading
              "
              :button-text="$t('CANNED_MGMT.ADD.FORM.SUBMIT')"
              :loading="addCanned.showLoading"
            />
            <button class="button clear" @click.prevent="onClose">
              {{ $t('CANNED_MGMT.ADD.CANCEL_BUTTON_TEXT') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
import AttachmentPreview from 'dashboard/components/widgets/AttachmentsPreview';
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor';
import {
  ALLOWED_FILE_TYPES,
  MAXIMUM_FILE_UPLOAD_SIZE,
} from 'shared/constants/messages';
import { MESSAGE_MAX_LENGTH } from 'shared/helpers/MessageTypeHelper';
import { checkFileSizeLimit } from 'shared/helpers/FileHelper';
import alertMixin from 'shared/mixins/alertMixin';
import { mixin as clickaway } from 'vue-clickaway';
import FileUpload from 'vue-upload-component';
import { minLength, required } from 'vuelidate/lib/validators';
import Modal from '../../../../components/Modal';
import WootSubmitButton from '../../../../components/buttons/FormSubmitButton';

const EmojiInput = () => import('shared/components/emoji/EmojiInput');

export default {
  components: {
    AttachmentPreview,
    EmojiInput,
    FileUpload,
    WootSubmitButton,
    Modal,
    WootMessageEditor,
  },
  mixins: [clickaway, alertMixin],
  props: {
    responseContent: {
      type: String,
      default: '',
    },
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isFocused: false,
      shortCode: '',
      title: '',
      isPrivate: false,
      content: this.responseContent || '',
      attachedFiles: [],
      addCanned: {
        showLoading: false,
        message: '',
      },
      show: true,
      showEmojiPicker: false,
      updateEditorSelectionWith: '',
    };
  },
  computed: {
    allowedFileTypes() {
      return ALLOWED_FILE_TYPES;
    },
    hasAttachments() {
      return this.attachedFiles.length;
    },
    charLengthClass() {
      return this.charactersRemaining < 0 ? 'message-error' : 'message-length';
    },
    characterLengthWarning() {
      return `${this.content.length} / ${this.maxLength}`;
      // return this.charactersRemaining < 0
      //   ? `${-this.charactersRemaining} characters over`
      //   : `${this.charactersRemaining} characters remaining`;
    },
    isMessageLengthReachingThreshold() {
      return this.content.length > this.maxLength - 50;
    },
    charactersRemaining() {
      return this.maxLength - this.content.length;
    },
    maxLength() {
      return MESSAGE_MAX_LENGTH.FACEBOOK;
    },
  },
  validations: {
    shortCode: {
      required,
      minLength: minLength(2),
    },
    title: {
      required,
    },
    content: {
      required,
    },
  },
  methods: {
    resetForm() {
      this.shortCode = '';
      this.title = '';
      this.content = '';
      this.isPrivate = true;
      this.$v.shortCode.$reset();
      this.$v.title.$reset();
      this.$v.content.$reset();
    },
    addCannedResponse() {
      // Show loading on button
      this.addCanned.showLoading = true;
      // Make API Calls
      this.$store
        .dispatch('createCannedResponse', {
          short_code: this.shortCode,
          title: this.title,
          content: this.content,
          is_private: this.isPrivate,
          attachments: this.attachedFiles.map(file => file.resource.file),
        })
        .then(() => {
          // Reset Form, Show success message
          this.addCanned.showLoading = false;
          this.showAlert(this.$t('CANNED_MGMT.ADD.API.SUCCESS_MESSAGE'));
          this.resetForm();
          this.onClose();
        })
        .catch(() => {
          this.addCanned.showLoading = false;
          this.showAlert(this.$t('CANNED_MGMT.ADD.API.ERROR_MESSAGE'));
        });
    },
    onFocus() {
      this.isFocused = true;
    },
    clearEditorSelection() {
      this.updateEditorSelectionWith = '';
    },
    toggleEmojiPicker() {
      this.showEmojiPicker = !this.showEmojiPicker;
    },
    hideEmojiPicker() {
      if (this.showEmojiPicker) {
        this.toggleEmojiPicker();
      }
    },
    emojiOnClick(emoji) {
      this.updateEditorSelectionWith = emoji;
      this.onFocus();
    },
    onFileUpload(file) {
      if (!file) {
        return;
      }
      if (checkFileSizeLimit(file, MAXIMUM_FILE_UPLOAD_SIZE)) {
        this.attachFile({ file });
      } else {
        this.showAlert(
          this.$t('CONVERSATION.FILE_SIZE_LIMIT', {
            MAXIMUM_FILE_UPLOAD_SIZE,
          })
        );
      }
    },
    attachFile({ blob, file }) {
      const reader = new FileReader();
      reader.readAsDataURL(file.file);
      reader.onloadend = () => {
        this.attachedFiles.push({
          resource: blob || file,
          thumb: reader.result,
          blobSignedId: blob ? blob.signed_id : undefined,
        });
      };
    },
    removeAttachment(itemIndex) {
      this.attachedFiles = this.attachedFiles.filter(
        (item, index) => itemIndex !== index
      );
    },
    onPaste(e) {
      const data = e.clipboardData.files;
      if (!data.length || !data[0]) {
        return;
      }
      data.forEach(file => {
        const { name, type, size } = file;
        this.onFileUpload({ name, type, size, file: file });
      });
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  .ProseMirror-menubar {
    display: none;
  }

  .ProseMirror-woot-style {
    min-height: 20rem;

    p {
      font-size: var(--font-size-default);
    }
  }

  .message-editor {
    border: 1px solid var(--s-200);
  }
  .content-label-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: var(--space-small);
  }
  .editor-tools {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: var(--space-small);
    margin-left: var(--space-small);
  }
  .emoji-dialog--expanded {
    left: calc(var(--space-large) + var(--space-smaller));
    top: 0;
    right: unset;
    z-index: var(--z-index-normal);
  }
}
.action-wrap {
  // display: flex;
  // align-items: center;
  margin: 0 var(--space-normal);
  font-size: var(--font-size-mini);

  .message-error {
    color: var(--r-600);
  }
  .message-length {
    color: var(--s-600);
  }
}
</style>
