export const MACRO_ACTION_TYPES = [
  {
    key: 'assign_team',
    label: 'Giao cho nhóm tư vấn',
    inputType: 'search_select',
  },
  {
    key: 'assign_agent',
    label: 'Giao cho tư vấn viên',
    inputType: 'search_select',
  },
  {
    key: 'add_label',
    label: 'Thêm nhãn hội thoại',
    inputType: 'multi_select',
  },
  {
    key: 'remove_label',
    label: 'Gỡ nhãn hội thoại',
    inputType: 'multi_select',
  },
  {
    key: 'remove_assigned_team',
    label: 'Gỡ nhóm tư vấn',
    inputType: null,
  },
  {
    key: 'send_email_transcript',
    label: 'Gửi transcript hội thoại đến email',
    inputType: 'email',
  },
  {
    key: 'mute_conversation',
    label: 'Tắt tiếng cuộc hội thoại',
    inputType: null,
  },
  {
    key: 'snooze_conversation',
    label: 'Tạm ẩn cuộc hội thoại',
    inputType: null,
  },
  {
    key: 'resolve_conversation',
    label: 'Đóng cuộc hội thoại',
    inputType: null,
  },
  {
    key: 'send_attachment',
    label: 'Gửi tệp đính kèm',
    inputType: 'attachment',
  },
  {
    key: 'send_message',
    label: 'Gửi tin nhắn',
    inputType: 'textarea',
  },
  {
    key: 'add_private_note',
    label: 'Gửi ghi chú riêng tư',
    inputType: 'textarea',
  },
];
