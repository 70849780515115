/* global axios */
import ApiClient from '../ApiClient';

class ZaloChannel extends ApiClient {
  constructor() {
    super('zalo_indicators', { accountScoped: true });
  }

  create(params) {
    return axios.post(
      `${this.url.replace(this.resource, '')}callbacks/register_zalo_page`,
      params
    );
  }

  reauthorizeZalo({ accessToken, refreshToken, pageId }) {
    return axios.post(`${this.baseUrl()}/callbacks/reauthorize_zalo`, {
      access_token: accessToken,
      refresh_token: refreshToken,
      page_id: pageId,
    });
  }
}

export default new ZaloChannel();
