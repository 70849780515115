<template>
  <div class="wizard-body columns content-box small-9">
    <div v-if="!hasLoginStarted" class="login-init full-height">
      <a href="#" @click="startLogin()">
        <img
          src="~dashboard/assets/images/channels/zalo_login.png"
          alt="Facebook-logo"
        />
      </a>
      <p>
        {{
          useInstallationName(
            $t('INBOX_MGMT.ADD.FB.HELP'),
            globalConfig.installationName
          )
        }}
      </p>
    </div>
    <div v-else>
      <loading-state v-if="showLoader" :message="emptyStateMessage" />
      <form v-if="!showLoader" class="row" @submit.prevent="createChannel()">
        <div class="medium-12 columns">
          <page-header
            :header-title="$t('INBOX_MGMT.ADD.DETAILS.TITLE')"
            :header-content="
              useInstallationName(
                $t('INBOX_MGMT.ADD.DETAILS.DESC'),
                globalConfig.installationName
              )
            "
          />
        </div>
        <div class="medium-7 columns">
          <div class="medium-12 columns">
            <div class="input-wrap" :class="{ error: $v.selectedPage.$error }">
              {{ $t('INBOX_MGMT.ADD.FB.CHOOSE_PAGE') }}
              <multiselect
                v-model.trim="selectedPage"
                :close-on-select="true"
                :allow-empty="true"
                :options="getSelectablePages"
                track-by="id"
                label="name"
                :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
                :placeholder="$t('INBOX_MGMT.ADD.FB.PICK_A_VALUE')"
                selected-label
                @select="setPageName"
              />
              <span v-if="$v.selectedPage.$error" class="message">
                {{ $t('INBOX_MGMT.ADD.FB.CHOOSE_PLACEHOLDER') }}
              </span>
            </div>
          </div>
          <div class="medium-12 columns">
            <label :class="{ error: $v.pageName.$error }">
              {{ $t('INBOX_MGMT.ADD.FB.INBOX_NAME') }}
              <input
                v-model.trim="pageName"
                type="text"
                :placeholder="$t('INBOX_MGMT.ADD.FB.PICK_NAME')"
                @input="$v.pageName.$touch"
              />
              <span v-if="$v.pageName.$error" class="message">
                {{ $t('INBOX_MGMT.ADD.FB.ADD_NAME') }}
              </span>
            </label>
          </div>
          <div class="medium-12 columns text-right">
            <input type="submit" value="Create Inbox" class="button" />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
/* eslint-env browser */
/* global FB */
import { required } from 'vuelidate/lib/validators';
import LoadingState from 'dashboard/components/widgets/LoadingState';
import { mapGetters } from 'vuex';
import ChannelApi from '../../../../../api/channels';
import PageHeader from '../../SettingsSubPageHeader';
import router from '../../../../index';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import accountMixin from '../../../../../mixins/account';
import { encode as base64encode } from 'base64-arraybuffer';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import axios from 'axios';

export default {
  components: {
    LoadingState,
    PageHeader,
  },
  mixins: [globalConfigMixin, accountMixin],
  data() {
    return {
      isCreating: false,
      omniauth_token: '',
      user_access_token: '',
      channel: 'zalo',
      selectedPage: { name: null, id: null },
      pageName: '',
      pageList: [],
      emptyStateMessage: this.$t('INBOX_MGMT.DETAILS.LOADING_FB'),
      hasLoginStarted: false,
    };
  },

  validations: {
    pageName: {
      required,
    },

    selectedPage: {
      isEmpty() {
        return this.selectedPage !== null && !!this.selectedPage.name;
      },
    },
  },

  computed: {
    showLoader() {
      return !this.user_access_token || this.isCreating;
    },
    getSelectablePages() {
      return this.pageList.filter(item => !item.exists);
    },
    ...mapGetters({
      currentUser: 'getCurrentUser',
      globalConfig: 'globalConfig/get',
    }),
  },

  created() {
    // this.initFB();
    // this.loadFBsdk();
    const zaloCodeVerifier = Cookies.get('ZaloCodeVerifier');
    const queryString =
      window.location.search === ''
        ? window.location.hash.substring(1)
        : window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('code');
    if (code && zaloCodeVerifier) {
      this.getZaloAccessToken(
        {
          authorizationCode: code,
          codeVerifier: zaloCodeVerifier,
        },
        {
          onSuccess: res => {
            // createZalos({
            //   data: {
            //     code: res?.code ?? '',
            //     longLivedToken: res?.refreshToken ?? '',
            //     name: res?.name ?? '',
            //     token: res?.accessToken ?? '',
            //   },
            // });
            // console.log(res);
            // const tmp = [];
            // tmp.push({
            //   name: res?.name ?? '',
            //   id: res?.code ?? '',
            //   access_token: res?.accessToken ?? '',
            //   long_live_token: res?.refreshToken ?? '',
            // });
            // this.pageList = tmp;
            // this.user_access_token = res?.accessToken;
            // console.log(
            //   this.pageList,
            //   this.user_access_token,
            //   !this.user_access_token
            // );
            this.setZaloData(res);
          },
          onError: () => {
            // showNotification({
            //   color: 'red',
            //   message: t('message.error.create'),
            // });
          },
        }
      );
    }
  },

  mounted() {
    this.initFB();
  },

  methods: {
    startLogin() {
      this.hasLoginStarted = true;
      this.handleZaloLogin();
    },

    setPageName({ name }) {
      this.$v.selectedPage.$touch();
      this.pageName = name;
    },

    setZaloData(res) {
      this.hasLoginStarted = true;
      const tmp = [];
      tmp.push({
        name: res?.name ?? '',
        id: res?.code ?? '',
        access_token: res?.accessToken ?? '',
        long_live_token: res?.refreshToken ?? '',
      });
      this.pageList = tmp;
      this.user_access_token = res?.accessToken || '';
    },

    initChannelAuth(channel) {
      if (channel === 'facebook') {
        this.loadFBsdk();
      }
    },

    initFB() {
      if (window.fbSDKLoaded === undefined) {
        window.fbAsyncInit = () => {
          FB.init({
            appId: window.chatwootConfig.fbAppId,
            xfbml: true,
            version: window.chatwootConfig.fbApiVersion,
            status: true,
          });
          window.fbSDKLoaded = true;
          FB.AppEvents.logPageView();
        };
      }
    },

    loadFBsdk() {
      ((d, s, id) => {
        let js;
        // eslint-disable-next-line
        const fjs = (js = d.getElementsByTagName(s)[0]);
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = '//connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    },

    tryFBlogin() {
      FB.login(
        response => {
          if (response.status === 'connected') {
            this.fetchPages(response.authResponse.accessToken);
          } else if (response.status === 'not_authorized') {
            // The person is logged into Facebook, but not your app.
            this.emptyStateMessage = this.$t(
              'INBOX_MGMT.DETAILS.ERROR_FB_AUTH'
            );
          } else {
            // The person is not logged into Facebook, so we're not sure if
            // they are logged into this app or not.
            this.emptyStateMessage = this.$t(
              'INBOX_MGMT.DETAILS.ERROR_FB_AUTH'
            );
          }
        },
        {
          scope:
            'pages_manage_metadata,pages_messaging,instagram_basic,pages_show_list,pages_read_engagement,instagram_manage_messages',
        }
      );
    },

    async fetchPages(_token) {
      try {
        const response = await ChannelApi.fetchFacebookPages(
          _token,
          this.accountId
        );
        const {
          data: { data },
        } = response;
        this.pageList = data.page_details;
        this.user_access_token = data.user_access_token;
      } catch (error) {
        // Ignore error
      }
    },

    channelParams() {
      return {
        page_refresh_token: this.selectedPage.long_live_token,
        page_access_token: this.selectedPage.access_token,
        page_id: this.selectedPage.id,
        inbox_name: this.selectedPage.name,
      };
    },

    createChannel() {
      this.$v.$touch();
      if (!this.$v.$error) {
        this.emptyStateMessage = this.$t('INBOX_MGMT.DETAILS.CREATING_CHANNEL');
        this.isCreating = true;
        this.$store
          .dispatch('inboxes/createZaloChannel', this.channelParams())
          .then(data => {
            router.replace({
              name: 'settings_inboxes_add_agents',
              params: { page: 'new', inbox_id: data.id },
            });
          })
          .catch(() => {
            this.isCreating = false;
          });
      }
    },

    // zalo
    getZaloAccessToken(payload, cb) {
      this.getAccessToken(payload)
        .then(atRes => {
          // console.log(atRes);
          // console.log(JSON.parse(atRes.data.body));
          const atData = JSON.parse(atRes.data.body || '{}');
          this.getOA({
            accessToken: atData.access_token,
          }).then(oaRes => {
            // console.log(oaRes);
            const oaData = JSON.parse(oaRes?.data?.body || '{}');
            if (oaData?.error !== 0 || oaData?.message !== 'Success') {
              cb?.onError?.(oaData?.message);
              return;
            }
            cb?.onSuccess?.({
              code: oaData?.data?.oa_id,
              name: oaData?.data?.name,
              accessToken: atData.access_token,
              refreshToken: atData.refresh_token,
            });
          });
        })
        .catch(cb?.onError)
        .finally(cb?.onFinally);
    },
    getAccessToken(payload) {
      const urlData = '/api/v1/integrations/zalo/auth';
      const fetchPromise = new Promise((resolve, reject) => {
        axios
          .post(
            urlData,
            {
              app_id: this.globalConfig.zaloAppId,
              code: payload.authorizationCode,
              grant_type: 'authorization_code',
              code_verifier: payload.codeVerifier,
            },
            {
              headers: {
                secret_key: this.globalConfig.zaloAppSecret,
              },
            }
          )
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
      return fetchPromise;
    },
    getOA(payload) {
      const urlData = '/api/v1/integrations/zalo/oa';
      const fetchPromise = new Promise((resolve, reject) => {
        axios
          .post(
            urlData,
            {
              access_token: payload.accessToken,
            },
            {
              headers: {
                access_token: payload.accessToken,
              },
            }
          )
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
      return fetchPromise;
    },
    async handleZaloLogin() {
      try {
        const {
          codeChallenge,
          codeVerifier,
          url,
        } = await this.getZaloAuthorizationCodeURL();

        const expires = this.getDateAfterDuration({
          duration: { input: 10, unit: 'm' },
        });
        // console.log(codeChallenge, codeVerifier, expires);

        Cookies.set('ZaloCodeChallenge', codeChallenge, {
          expires,
        });
        Cookies.set('ZaloCodeVerifier', codeVerifier, {
          expires,
        });

        window.open(url, '_self');
      } catch (error) {
        // console.log(error);
      } finally {
        this.hasLoginStarted = false;
        // setLoading({ zalo: false });
      }
    },

    async getZaloAuthorizationCodeURL() {
      const { codeChallenge, codeVerifier } = await this.generatePKCEPair();

      const url = `https://oauth.zaloapp.com/v4/oa/permission?${this.toQueryString(
        {
          app_id: this.globalConfig.zaloAppId,
          redirect_uri: document.URL,
          code_challenge: codeChallenge,
          // state: generateRandomString({ length: 6 }),
          state: this.generateString(6),
        }
      )}`;

      return { codeChallenge, codeVerifier, url };
    },

    getDateAfterDuration({
      currentDate = new Date(),
      duration: { input, unit },
    }) {
      return dayjs(currentDate)
        .add(input, unit)
        .toDate();
    },

    toQueryString(rawQuery) {
      const query = rawQuery || {};
      const keys = Object.keys(query).filter(
        key => typeof query[key] !== 'undefined'
      );
      return keys
        .map(key =>
          Array.isArray(query[key])
            ? this.addArrayQueryParam(query, key)
            : this.addQueryParam(query, key)
        )
        .join('&');
    },

    addQueryParam(query, key) {
      return this.encodeQueryParam(key, query[key]);
    },
    addArrayQueryParam(query, key) {
      const value = query[key];
      return value.map(v => this.encodeQueryParam(key, v)).join('&');
    },
    encodeQueryParam(key, value) {
      const encodedKey = encodeURIComponent(key);
      return `${encodedKey}=${encodeURIComponent(
        typeof value === 'number' ? value : `${value}`
      )}`;
    },

    async generatePKCEPair() {
      // const codeVerifier = generateRandomString({
      //   length: verifierLength,
      //   type: 'alphanumeric',
      // });
      const codeVerifier = this.generateString(43);

      const encoder = new TextEncoder();
      const data = encoder.encode(codeVerifier);
      const digest = await window.crypto.subtle.digest('SHA-256', data);
      const base64Digest = base64encode(digest);

      const codeChallenge = base64Digest
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');

      return {
        codeVerifier,
        codeChallenge,
      };
    },

    generateString(length) {
      const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = ' ';
      const charactersLength = characters.length;
      for (let i = 0; i < length; i += 1) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }

      return result;
    },
  },
};
</script>
