<template>
  <modal
    :show.sync="show"
    :on-close="onClose"
    :close-on-backdrop-click="false"
    :on-click-modal="hideEmojiPicker"
  >
    <div class="column content-box">
      <woot-modal-header :header-title="pageTitle" />
      <form class="row medium-8" @submit.prevent="editCannedResponse()">
        <div class="medium-12 columns">
          <label :class="{ error: $v.shortCode.$error }">
            {{ $t('CANNED_MGMT.EDIT.FORM.SHORT_CODE.LABEL') }}
            <input
              v-model.trim="shortCode"
              type="text"
              :placeholder="$t('CANNED_MGMT.EDIT.FORM.SHORT_CODE.PLACEHOLDER')"
              @input="$v.shortCode.$touch"
            />
          </label>
        </div>

        <div class="medium-12 columns">
          <label :class="{ error: $v.title.$error }">
            {{ $t('CANNED_MGMT.EDIT.FORM.TITLE.LABEL') }}
            <input
              v-model.trim="title"
              type="text"
              :placeholder="$t('CANNED_MGMT.EDIT.FORM.TITLE.PLACEHOLDER')"
              @input="$v.title.$touch"
            />
          </label>
        </div>

        <div class="medium-12 columns">
          <div class="content-label-wrapper">
            <label :class="{ error: $v.content.$error }">
              {{ $t('CANNED_MGMT.EDIT.FORM.CONTENT.LABEL') }}
            </label>
            <div class="editor-tools">
              <woot-button
                type="button"
                :title="$t('CONVERSATION.REPLYBOX.TIP_EMOJI_ICON')"
                icon="emoji"
                emoji="😊"
                color-scheme="secondary"
                variant="smooth"
                size="small"
                @click="toggleEmojiPicker"
              />
              <emoji-input
                v-if="showEmojiPicker"
                v-on-clickaway="hideEmojiPicker"
                class="emoji-dialog--expanded"
                :on-click="emojiOnClick"
              />

              <file-upload
                ref="upload"
                :size="4096 * 4096"
                :accept="allowedFileTypes"
                :multiple="true"
                :drop="true"
                :drop-directory="false"
                :data="{
                  direct_upload_url: '/rails/active_storage/direct_uploads',
                  direct_upload: true,
                }"
                @input-file="onFileUpload"
              >
                <woot-button
                  type="button"
                  class-names="button--upload"
                  :title="$t('CONVERSATION.REPLYBOX.TIP_ATTACH_ICON')"
                  icon="attach"
                  emoji="📎"
                  color-scheme="secondary"
                  variant="smooth"
                  size="small"
                />
              </file-upload>
            </div>
            <div class="action-wrap">
              <div class="tabs-title">
                <span :class="charLengthClass">
                  {{ characterLengthWarning }}
                </span>
              </div>
            </div>
          </div>
          <div class="editor-wrap">
            <woot-message-editor
              v-model="content"
              class="message-editor"
              :class="{ editor_warning: $v.content.$error }"
              :enable-variables="true"
              :enable-canned-responses="false"
              :placeholder="$t('CANNED_MGMT.EDIT.FORM.CONTENT.PLACEHOLDER')"
              :update-selection-with="updateEditorSelectionWith"
              @clear-selection="clearEditorSelection"
              @blur="$v.content.$touch"
              @focus="onFocus"
            />
            <div
              v-if="hasAttachments"
              class="attachment-preview-box"
              @paste="onPaste"
            >
              <attachment-preview
                :attachments="attachedFiles"
                :remove-attachment="removeAttachment"
              />
            </div>
          </div>
        </div>

        <div v-if="shouldAllowPrivate" class="medium-12">
          <input v-model="isPrivate" type="checkbox" :value="false" />
          <label for="is_private">
            {{ $t('CANNED_MGMT.EDIT.FORM.IS_PRIVATE.LABEL') }}
          </label>
        </div>

        <div v-if="shouldAllowPrivate" class="modal-footer">
          <div class="medium-12 columns">
            <woot-submit-button
              :disabled="
                $v.content.$invalid ||
                  $v.shortCode.$invalid ||
                  $v.title.$invalid ||
                  editCanned.showLoading
              "
              :button-text="$t('CANNED_MGMT.EDIT.FORM.SUBMIT')"
              :loading="editCanned.showLoading"
            />
            <button class="button clear" @click.prevent="onClose">
              {{ $t('CANNED_MGMT.EDIT.CANCEL_BUTTON_TEXT') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
/* eslint no-console: 0 */
import AttachmentPreview from 'dashboard/components/widgets/AttachmentsPreview';
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor';
import {
  ALLOWED_FILE_TYPES,
  MAXIMUM_FILE_UPLOAD_SIZE,
} from 'shared/constants/messages';
import { checkFileSizeLimit, urlToFile } from 'shared/helpers/FileHelper';
import alertMixin from 'shared/mixins/alertMixin';
import { mixin as clickaway } from 'vue-clickaway';
import FileUpload from 'vue-upload-component';
import { minLength, required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import Modal from '../../../../components/Modal';
import WootSubmitButton from '../../../../components/buttons/FormSubmitButton';
import { MESSAGE_MAX_LENGTH } from 'shared/helpers/MessageTypeHelper';

const EmojiInput = () => import('shared/components/emoji/EmojiInput');

export default {
  components: {
    AttachmentPreview,
    EmojiInput,
    FileUpload,
    WootSubmitButton,
    Modal,
    WootMessageEditor,
  },
  mixins: [clickaway, alertMixin],
  props: {
    id: { type: Number, default: null },
    edcontent: { type: String, default: '' },
    edshortCode: { type: String, default: '' },
    edtitle: { type: String, default: '' },
    edfiles: { type: Array, default: () => [] },
    edisPrivate: { type: Boolean, default: false },
    edsubmitterId: { type: Number, default: null },
    onClose: { type: Function, default: () => {} },
  },
  data() {
    return {
      editCanned: {
        showAlert: false,
        showLoading: false,
        message: '',
      },
      shortCode: this.edshortCode,
      title: this.edtitle,
      content: this.edcontent,
      isPrivate: this.edisPrivate,
      submitterId: this.edsubmitterId,
      attachedFiles: [],
      show: true,
      showEmojiPicker: false,
      updateEditorSelectionWith: '',
    };
  },
  validations: {
    shortCode: {
      required,
      minLength: minLength(2),
    },
    title: {
      required,
    },
    content: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
      currentUser: 'getCurrentUser',
    }),
    pageTitle() {
      return `${this.$t('CANNED_MGMT.EDIT.TITLE')} - ${this.edshortCode}`;
    },
    allowedFileTypes() {
      return ALLOWED_FILE_TYPES;
    },
    hasAttachments() {
      return this.attachedFiles.length;
    },
    shouldAllowPrivate() {
      return this.currentUser.id === this.submitterId;
    },
    charLengthClass() {
      return this.charactersRemaining < 0 ? 'message-error' : 'message-length';
    },
    characterLengthWarning() {
      return `${this.content.length} / ${this.maxLength}`;
      // return this.charactersRemaining < 0
      //   ? `${-this.charactersRemaining} characters over`
      //   : `${this.charactersRemaining} characters remaining`;
    },
    isMessageLengthReachingThreshold() {
      return this.content.length > this.maxLength - 50;
    },
    charactersRemaining() {
      return this.maxLength - this.content.length;
    },
    maxLength() {
      return MESSAGE_MAX_LENGTH.FACEBOOK;
    },
  },
  async mounted() {
    // this.shortCode = this.edshortCode;
    // this.title = this.edtitle;
    // this.content = this.edcontent;
    if (this.edfiles && this.edfiles.length !== 0) {
      const files = await Promise.all(
        this.edfiles.map(async edfile => {
          const url = edfile.file_url.replace(
            this.globalConfig.apiHost,
            this.globalConfig.hostURL
          );
          return urlToFile({
            url,
            filename: edfile.filename,
            type: edfile.file_type,
            blob_id: edfile.blob_id,
          });
        })
      );
      this.attachedFiles = files;
    }
  },
  methods: {
    setPageName({ name }) {
      this.$v.content.$touch();
      this.content = name;
    },
    showAlert() {
      bus.$emit('newToastMessage', this.editCanned.message);
    },
    resetForm() {
      this.shortCode = '';
      this.title = '';
      this.content = '';
      this.attachedFiles = [];
      this.isPrivate = false;
      this.$v.shortCode.$reset();
      this.$v.title.$reset();
      this.$v.content.$reset();
    },
    editCannedResponse() {
      // Show loading on button
      this.editCanned.showLoading = true;
      // Make API Calls
      this.$store
        .dispatch('updateCannedResponse', {
          id: this.id,
          short_code: this.shortCode,
          title: this.title,
          content: this.content,
          is_private: this.isPrivate,
          attachments: this.attachedFiles.map(file =>
            file.resource instanceof File ? file.resource : file.resource.file
          ),
        })
        .then(() => {
          // Reset Form, Show success message
          this.editCanned.showLoading = false;
          this.editCanned.message = this.$t(
            'CANNED_MGMT.EDIT.API.SUCCESS_MESSAGE'
          );
          this.showAlert();
          this.resetForm();
          setTimeout(() => {
            this.onClose();
          }, 10);
        })
        .catch(() => {
          this.editCanned.showLoading = false;
          this.editCanned.message = this.$t(
            'CANNED_MGMT.EDIT.API.ERROR_MESSAGE'
          );
          this.showAlert();
        });
    },
    onFocus() {
      this.isFocused = true;
    },
    clearEditorSelection() {
      this.updateEditorSelectionWith = '';
    },
    toggleEmojiPicker() {
      this.showEmojiPicker = !this.showEmojiPicker;
    },
    hideEmojiPicker() {
      if (this.showEmojiPicker) {
        this.toggleEmojiPicker();
      }
    },
    emojiOnClick(emoji) {
      this.updateEditorSelectionWith = emoji;
      this.onFocus();
    },
    onFileUpload(file) {
      if (!file) {
        return;
      }
      if (checkFileSizeLimit(file, MAXIMUM_FILE_UPLOAD_SIZE)) {
        this.attachFile({ file });
      } else {
        this.showAlert(
          this.$t('CONVERSATION.FILE_SIZE_LIMIT', {
            MAXIMUM_FILE_UPLOAD_SIZE,
          })
        );
      }
    },
    attachFile({ blob, file }) {
      const reader = new FileReader();
      reader.readAsDataURL(file.file);
      reader.onloadend = () => {
        this.attachedFiles.push({
          resource: blob || file,
          thumb: reader.result,
          blobSignedId: blob ? blob.signed_id : undefined,
        });
      };
    },
    removeAttachment(itemIndex) {
      this.attachedFiles = this.attachedFiles.filter(
        (item, index) => itemIndex !== index
      );
    },
    onPaste(e) {
      const data = e.clipboardData.files;
      if (!data.length || !data[0]) {
        return;
      }
      data.forEach(file => {
        const { name, type, size } = file;
        this.onFileUpload({ name, type, size, file: file });
      });
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep {
  .ProseMirror-menubar {
    display: none;
  }

  .ProseMirror-woot-style {
    min-height: 20rem;

    p {
      font-size: var(--font-size-default);
    }
  }

  .message-editor {
    border: 1px solid var(--s-200);
  }
  .content-label-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: var(--space-small);
  }
  .editor-tools {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: var(--space-small);
    margin-left: var(--space-small);
  }
  .emoji-dialog--expanded {
    left: calc(var(--space-large) + var(--space-smaller));
    top: 0;
    right: unset;
    z-index: var(--z-index-normal);
  }
}
.action-wrap {
  // display: flex;
  // align-items: center;
  margin: 0 var(--space-normal);
  font-size: var(--font-size-mini);

  .message-error {
    color: var(--r-600);
  }
  .message-length {
    color: var(--s-600);
  }
}
</style>
