import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import CustomFieldAPI from '../../api/customFields';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
  },
};

export const getters = {
  getCustomFields($state) {
    return $state.records;
  },
  getVerifiedCustomFields($state) {
    return $state.records.filter(record => record.confirmed);
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getCustomFieldStatus($state) {
    let status = {
      online: $state.records.filter(
        customField => customField.availability_status === 'online'
      ).length,
      busy: $state.records.filter(
        customField => customField.availability_status === 'busy'
      ).length,
      offline: $state.records.filter(
        customField => customField.availability_status === 'offline'
      ).length,
    };
    return status;
  },
};

export const actions = {
  get: async ({ commit }) => {
    commit(types.default.SET_CUSTOM_FIELD_FETCHING_STATUS, true);
    try {
      const response = await CustomFieldAPI.get();
      commit(types.default.SET_CUSTOM_FIELD_FETCHING_STATUS, false);
      commit(types.default.SET_CUSTOM_FIELDS, response.data);
    } catch (error) {
      commit(types.default.SET_CUSTOM_FIELD_FETCHING_STATUS, false);
    }
  },
  create: async ({ commit }, customFieldInfo) => {
    commit(types.default.SET_CUSTOM_FIELD_CREATING_STATUS, true);
    try {
      const response = await CustomFieldAPI.create(customFieldInfo);
      commit(types.default.ADD_CUSTOM_FIELD, response.data);
      commit(types.default.SET_CUSTOM_FIELD_CREATING_STATUS, false);
    } catch (error) {
      commit(types.default.SET_CUSTOM_FIELD_CREATING_STATUS, false);
      throw error;
    }
  },
  update: async ({ commit }, { id, ...customFieldParams }) => {
    commit(types.default.SET_CUSTOM_FIELD_UPDATING_STATUS, true);
    try {
      const response = await CustomFieldAPI.update(id, customFieldParams);
      commit(types.default.EDIT_CUSTOM_FIELD, response.data);
      commit(types.default.SET_CUSTOM_FIELD_UPDATING_STATUS, false);
    } catch (error) {
      commit(types.default.SET_CUSTOM_FIELD_UPDATING_STATUS, false);
      throw new Error(error);
    }
  },
  updateSingleCustomFieldPresence: ({ commit }, { id, availabilityStatus }) => {
    commit(types.default.UPDATE_SINGLE_CUSTOM_FIELD_PRESENCE, {
      id,
      availabilityStatus,
    });
  },
  updatePresence: async ({ commit }, data) => {
    commit(types.default.UPDATE_CUSTOM_FIELDS_PRESENCE, data);
  },
  delete: async ({ commit }, customFieldId) => {
    commit(types.default.SET_CUSTOM_FIELD_DELETING_STATUS, true);
    try {
      await CustomFieldAPI.delete(customFieldId);
      commit(types.default.DELETE_CUSTOM_FIELD, customFieldId);
      commit(types.default.SET_CUSTOM_FIELD_DELETING_STATUS, false);
    } catch (error) {
      commit(types.default.SET_CUSTOM_FIELD_DELETING_STATUS, false);
      throw new Error(error);
    }
  },
};

export const mutations = {
  [types.default.SET_CUSTOM_FIELD_FETCHING_STATUS]($state, status) {
    $state.uiFlags.isFetching = status;
  },
  [types.default.SET_CUSTOM_FIELD_CREATING_STATUS]($state, status) {
    $state.uiFlags.isCreating = status;
  },
  [types.default.SET_CUSTOM_FIELD_UPDATING_STATUS]($state, status) {
    $state.uiFlags.isUpdating = status;
  },
  [types.default.SET_CUSTOM_FIELD_DELETING_STATUS]($state, status) {
    $state.uiFlags.isDeleting = status;
  },

  [types.default.SET_CUSTOM_FIELDS]: MutationHelpers.set,
  [types.default.ADD_CUSTOM_FIELD]: MutationHelpers.create,
  [types.default.EDIT_CUSTOM_FIELD]: MutationHelpers.update,
  [types.default.DELETE_CUSTOM_FIELD]: MutationHelpers.destroy,
  [types.default.UPDATE_CUSTOM_FIELDS_PRESENCE]: MutationHelpers.updatePresence,
  [types.default.UPDATE_SINGLE_CUSTOM_FIELD_PRESENCE]: (
    $state,
    { id, availabilityStatus }
  ) =>
    MutationHelpers.updateSingleRecordPresence($state.records, {
      id,
      availabilityStatus,
    }),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
