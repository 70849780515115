<template>
  <woot-custom-reports
    key="custom-field-reports"
    type="customField"
    getter-key="customFields/getCustomFields"
    action-key="customFields/get"
    :download-button-label="$t('TEAM_REPORTS.DOWNLOAD_TEAM_REPORTS')"
  />
</template>

<script>
import WootCustomReports from './components/WootCustomReports';

export default {
  components: {
    WootCustomReports,
  },
};
</script>
