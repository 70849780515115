<template>
  <div
    class="message-text__wrap"
    :class="{
      'show--quoted': isQuotedContentPresent,
      'hide--quoted': !isQuotedContentPresent,
      'hide--comment': isHide,
    }"
  >
    <div v-if="!isEmail" v-dompurify-html="message" class="text-content" />
    <letter v-else class="text-content" :html="message" />
    <button
      v-if="showQuoteToggle"
      class="quoted-text--button"
      @click="toggleQuotedContent"
    >
      <span v-if="showQuotedContent">
        <fluent-icon icon="chevron-up" class="fluent-icon" size="16" />
        {{ $t('CHAT_LIST.HIDE_QUOTED_TEXT') }}
      </span>
      <span v-else>
        <fluent-icon icon="chevron-down" class="fluent-icon" size="16" />
        {{ $t('CHAT_LIST.SHOW_QUOTED_TEXT') }}
      </span>
    </button>
  </div>
</template>

<script>
import Letter from 'vue-letter';

export default {
  components: { Letter },
  props: {
    verb: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    isEmail: {
      type: Boolean,
      default: true,
    },
    displayQuotedButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showQuotedContent: false,
    };
  },
  computed: {
    isQuotedContentPresent() {
      if (!this.isEmail) {
        return this.message.includes('<blockquote');
      }
      return this.showQuotedContent;
    },
    isHide() {
      if (this.verb === 'hide') {
        return true;
      }
      return false;
    },
    showQuoteToggle() {
      if (!this.isEmail) {
        return false;
      }
      return this.displayQuotedButton;
    },
  },
  methods: {
    toggleQuotedContent() {
      this.showQuotedContent = !this.showQuotedContent;
    },
  },
};
</script>
<style lang="scss">
.text-content {
  overflow: auto;

  ul,
  ol {
    padding-left: var(--space-two);
  }
  table {
    margin: 0;
    border: 0;

    td {
      margin: 0;
      border: 0;
    }

    tr {
      border-bottom: 0 !important;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: var(--font-size-normal);
  }
}

.show--quoted {
  blockquote {
    display: block;
  }
}

.hide--comment {
  color: var(--s-400);
}

.hide--quoted {
  blockquote {
    display: none;
  }
}

.quoted-text--button {
  color: var(--s-400);
  cursor: pointer;
  font-size: var(--font-size-mini);
  padding-bottom: var(--space-small);
  padding-top: var(--space-small);

  .fluent-icon {
    margin-bottom: var(--space-minus-smaller);
  }
}
</style>
